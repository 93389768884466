import { StatementsSelection } from './StatementsSelection'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function StatementsSelectionApp() {
  return (
    <FirebaseContext>
      <StatementsSelection />
    </FirebaseContext>
  )
}
