import { MiniCard, MiniCardList } from '/features/buildingBlocks/MiniCard'
import { ButtonLink } from '/features/buildingBlocks/Button'
import { Checkbox } from '/features/buildingBlocks/Checkbox'
import { Heading42 } from '/features/buildingBlocks/Heading'
import { routeMap } from '/routeMap'
import { Loader } from '/features/buildingBlocks/Loader'
import { useUserSelectedStatements } from '/domain/useUserSelectedStatements'
import { useUser } from '/machinery/FirebaseAuthProvider'
import { ref, set } from 'firebase/database'
import { useReportError } from '/machinery/ReportError'
import { useFirebaseDatabase } from '/machinery/useFirebaseDatabase'

import styles from './StatementsSelection.css'

export function StatementsSelection() {
  const reportError = useReportError()
  const { user } = useUser()
  const database = useFirebaseDatabase()

  const { data, isFetching } = useUserSelectedStatements()
  const amountOfSelected = data ? Object.values(data).filter(x => x.subjectToDiscus).length : 0
  const showNextButton = amountOfSelected >= 1 && amountOfSelected <= 4

  const selectedCopy = amountOfSelected > 0
    ? amountOfSelected > 4
      ? `${amountOfSelected - 4} te veel`
      : amountOfSelected
    : 'nog geen'

  if (isFetching) return <Loader />

  return (
    <div className={styles.component}>
      <MiniCardList>
        {
          Object.values(data).map(statement => {
            return (
              <MiniCard key={statement.id} tag='button' onClick={() => onSelectStatement(statement)}>
                <div className={styles.content}>
                  {statement.statement}
                  <Checkbox checked={statement.subjectToDiscus} layoutClassName={styles.checkboxLayout} />
                </div>
              </MiniCard>
            )
          })
        }
      </MiniCardList>

      <Heading42
        h={3}
        title={`U heeft er ${selectedCopy} geselecteerd`}
        layoutClassName={styles.counterLayout}
      />

      {showNextButton && <ButtonLink href={routeMap.app.stepThree.selected({ language: 'nl' })}>Ga verder</ButtonLink>}
    </div>
  )

  function onSelectStatement(statement) {
    try {
      if (!database) return

      set(ref(database, `/users/${user.uid}/statements/${statement.id}/subjectToDiscus`), !statement.subjectToDiscus)
    } catch (e) {
      reportError(e)
    }
  }
}
